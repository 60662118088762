import qs from '@scoir/query-string'

import api from 'common/api'
import { clearLocalStorage, setCookies, routeToApp } from 'common/login-service'
import { getTrackEventsSessionId } from 'common/get-track-events-session-id'

type RegisterResponse = {
  access_token: string
  UserTypes: string[]
}

type StudentInviteResponse = {
  Email: string
  FirstName: string
  HighSchool: {
    City: string
    State: string
    HighSchoolName: string
  }
  MatchedHighSchoolStudent: {
    FirstName: string
    LastName: string
  }
  Token: string
}
export const validateStudentInvite = async (
  token: string,
  setError: any,
  setInitialValues: any,
  setLoading: any,
  setTokenAuth: any,
  setUser: any
) => {
  api
    .post<{}, StudentInviteResponse>(`/api/student/register/invite/validate/${token}`, {})
    .then((auth) => {
      setTokenAuth(auth)
      setUser({
        name: auth.MatchedHighSchoolStudent.FirstName,
        location: `${auth.HighSchool.City}, ${auth.HighSchool.State}`,
        highschool: auth.HighSchool.HighSchoolName,
        email: auth.Email,
      })
      setInitialValues({
        password: {
          pass: '',
          passConfirm: '',
        },
        confirmTOS: false,
        recaptchaResponse: '',
      })
      setLoading(false)
    })
    .catch(() => {
      setError(true)
      setLoading(false)
    })
}

export const registerStudentUser = async (values: any, tokenAuth: any, setConfirmError: any, setSubmitting: any) => {
  const body: { [key: string]: any } = {
    ...tokenAuth,
    Category: 'High School Student',
    FirstName: tokenAuth.MatchedHighSchoolStudent.FirstName,
    HighSchoolStudentId: tokenAuth.MatchedHighSchoolStudent.HighSchoolStudentId,
    LastName: tokenAuth.MatchedHighSchoolStudent.LastName,
    GraduationYear: tokenAuth.MatchedHighSchoolStudent.GraduationYear,
    Password: {
      Pass: values.password.pass,
      PassConfirm: values.password.passConfirm,
    },
    RecaptchaResponse: values.recaptchaResponse,
  }
  const params = qs.stringify({ sessionId: getTrackEventsSessionId() })

  api
    .post<{}, RegisterResponse>(`/api/student/confirm?${params}`, body)
    .then((loginResponse) => {
      if (loginResponse.access_token && loginResponse.UserTypes) {
        const userType = loginResponse.UserTypes[0]
        clearLocalStorage(userType)
        setCookies(userType, loginResponse.access_token)
        routeToApp(userType)
      } else {
        setConfirmError(true)
        setSubmitting(false)
      }
    })
    .catch(() => {
      setConfirmError(true)
      setSubmitting(false)
    })
}

type ConfirmResponse = {
  ConfirmationCode: string
  Email: string
  FirstName: string
  LastName: string
  Token: string
  UserType: string[]
  Id: string
}

export const validateParentInvite = async (
  id: string,
  code: string,
  setError: any,
  setInitialValues: any,
  setLoading: any,
  setTokenAuth: any,
  setUser: any
) => {
  return api
    .get<ConfirmResponse>(`/api/confirm/${id}/${code}`)
    .then((auth) => {
      setTokenAuth(auth)
      setUser({ email: auth.Email, name: auth.FirstName })
      setInitialValues({
        password: {
          pass: '',
          passConfirm: '',
        },
      })
      setLoading(false)
    })
    .catch(() => {
      setError(true)
      setLoading(false)
    })
}

export const registerParentUser = async (values: any, tokenAuth: any, setConfirmError: any, setSubmitting: any) => {
  api
    .post<{}, RegisterResponse>('/api/confirm', {
      ...tokenAuth,
      isValid: true,
      Password: {
        Pass: values.password.pass,
        PassConfirm: values.password.passConfirm,
      },
    })
    .then((res) => {
      if (res.access_token && res.UserTypes[0]) {
        const userType = res.UserTypes[0]
        clearLocalStorage(userType)
        setCookies(userType, res.access_token)
        routeToApp(userType)
      } else {
        setConfirmError(true)
        setSubmitting(false)
      }
    })
    .catch(() => {
      setConfirmError(true)
      setSubmitting(false)
    })
}
