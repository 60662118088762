import React, { ReactNode } from 'react'

import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'left',
    fontWeight: 400,
    fontFamily: 'Calistoga',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  onlySubtitle: {
    margin: theme.spacing(2, 0),
  },
}))

const Title = ({ title, subtitle }: { title?: ReactNode; subtitle?: ReactNode }) => {
  const classes = useStyles()
  return (
    <>
      {title && (
        <Typography
          variant="h2"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="body2"
          className={title ? classes.subtitle : classes.onlySubtitle}
        >
          {subtitle}
        </Typography>
      )}
    </>
  )
}

export default Title
