import { createTheme } from '@material-ui/core/styles'
import { DEFAULT_THEME_CONFIG } from '@scoir/mui-theme'

const AuthDefaultTheme = {
  ...DEFAULT_THEME_CONFIG,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 40,
        width: '100%',
      },
    },
  },
}

const theme = createTheme(AuthDefaultTheme)

export default theme
