import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import { Button, TextField, Typography, makeStyles } from '@material-ui/core'
import * as Yup from 'yup'
import Title from 'components/Title'
import api from 'common/api'

const useStyles = makeStyles((theme) => ({
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectField: {
    textAlign: 'left',
  },
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export type ContactCounselorFormValues = {
  firstName: string
  lastName: string
}

type ContactCounselorFormProps = {
  onCancel: () => void
  student?: {
    FirstName: string
    LastName: string
    HighSchoolStudentId: string
  }
  highschool?: {
    HighSchoolId: string
  }
  email?: string
}

const ContactCounselorForm = ({ onCancel, student, highschool, email }: ContactCounselorFormProps) => {
  const classes = useStyles()
  const history = useHistory()
  const [error, setError] = useState<boolean>(false)

  const yupValidation = Yup.object().shape({
    firstName: Yup.string().required('This is Required.'),
    lastName: Yup.string().required('This is Required.'),
  })

  const handleSubmit = (values: ContactCounselorFormValues, props: FormikHelpers<ContactCounselorFormValues>) => {
    props.setSubmitting(true)
    api
      .post<{}, {}>('/api/user/student/validate/errors', {
        ...student,
        ...highschool,
        Email: email,
        FirstName: values.firstName,
        LastName: values.lastName,
      })
      .then(() => {
        history.push('/signup/student')
      })
      .catch(() => {
        setError(true)
        props.setSubmitting(false)
      })
  }

  return (
    <>
      <Title
        title="Let us know who you are"
        subtitle={`It says I’m ${student?.FirstName} ${student?.LastName}, but my name is:`}
      />
      <Formik
        initialValues={{ firstName: '', lastName: '' }}
        validateOnMount
        validationSchema={yupValidation}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isValid, isSubmitting }) => (
          <Form
            className={classes.contactForm}
            name="contactCounselor"
          >
            <Field
              id="firstName"
              name="firstName"
              as={TextField}
              required
              autoFocus
              fullWidth
              variant="outlined"
              label="First Name"
              helperText={<ErrorMessage name="firstName" />}
              error={errors.firstName && touched.firstName}
            />
            <Field
              id="lastName"
              name="lastName"
              as={TextField}
              required
              fullWidth
              variant="outlined"
              label="Last Name"
              helperText={<ErrorMessage name="lastName" />}
              error={errors.lastName && touched.lastName}
            />
            {error && (
              <Typography
                role="alert"
                color="error"
              >
                An error occurred while submitting, please try again later.
              </Typography>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || isSubmitting}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
      <Button
        onClick={onCancel}
        color="primary"
      >
        Cancel
      </Button>
    </>
  )
}

export default ContactCounselorForm
