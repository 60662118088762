import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Typography, makeStyles } from '@material-ui/core'

// @ts-expect-error
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import { get, includes, toInteger } from 'lodash'
import cookie from 'cookie'

import { COBRAND_ALIAS, SOURCES } from 'common/coalition-sources'

import SignInForm from './SignInForm'
import SignInErrorConstants from './SignInErrorConstants'
import SSOButtons from 'components/SSOButtons'
import OrLine from 'components/OrLine'
import Title from 'components/Title'
import ErrorBox from 'components/ErrorBox'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    margin: theme.spacing(2, 0),
  },
}))

const useQuery = () => {
  const { search } = useLocation()
  return useMemo(() => {
    const params = new URLSearchParams(search)
    return {
      scid: toInteger(params.get('scid')),
      source: params.get('source') as 'C' | 'CM',
    }
  }, [search])
}

const DEFAULT_ROUTE = '/signup'
const useRegisterLink = (sourceAlias?: 'C' | 'CM', scid?: number) =>
  useMemo(() => {
    let link = DEFAULT_ROUTE
    if (sourceAlias && includes(SOURCES, COBRAND_ALIAS[sourceAlias])) {
      link = '/app/signup'
      if (scid) {
        link += `/${scid}`
      }
    }
    return link
  }, [sourceAlias, scid])

export const SignInRoute = ({
  errorCode,
}: {
  errorCode?:
    | 'inactive'
    | 'clever'
    | 'cleverUnauthorized'
    | 'classLink'
    | 'classLinkUnauthorized'
    | 'google'
    | 'userNotFound'
}) => {
  const classes = useStyles()
  const errorMessage = SignInErrorConstants(errorCode)
  const [error, setError] = React.useState(errorMessage)
  const { source, scid } = useQuery()
  const actualSource = COBRAND_ALIAS[source]
  const registerLink = useRegisterLink(source, scid)
  const isDeepLink = () => {
    const parsedCookie = cookie.parse(document.cookie)
    const remembered = get(parsedCookie, 'remembered')
    return includes(remembered, '/admissions/')
  }
  return (
    <>
      {error && <ErrorBox error={error} />}
      <Title title="Log in 👋" />
      <SignInForm
        onError={setError}
        scid={scid}
        source={source}
      />
      <Link
        to="/forgot-password"
        className={classes.link}
      >
        Forgot password?
      </Link>

      <OrLine />

      <SSOButtons
        actualSource={actualSource}
        scid={scid}
      />

      <div className="signup-wrapper">
        {isDeepLink() ? (
          <Typography color="textSecondary">
            New to Scoir?{' '}
            <a
              className={classes.link}
              title="Create an account"
              href="http://www.scoir.com/colleges-signup"
            >
              Create an Account
            </a>
          </Typography>
        ) : (
          <Typography color="textSecondary">
            New to Scoir?{' '}
            <Link
              className={classes.link}
              to={registerLink}
            >
              Create Free Account
            </Link>
          </Typography>
        )}
      </div>
    </>
  )
}

const urlPropsQueryConfig = {
  errorCode: { type: UrlQueryParamTypes.string },
}

export default addUrlProps({ urlPropsQueryConfig })(SignInRoute)
