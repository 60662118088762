import React from 'react'

import { Typography, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  returnToLogin: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
}))

const ReturnToLogin = () => {
  const classes = useStyles()
  return (
    <Typography
      color="textSecondary"
      className={classes.returnToLogin}
    >
      Already have an account?{' '}
      <Link
        to="/signin"
        className={classes.link}
      >
        Log In
      </Link>
    </Typography>
  )
}

export default ReturnToLogin
