import React from 'react'
import { MenuItem, TextField } from '@material-ui/core'

import reasons, { OTHER_REASON } from './unsubscribe-reasons'

const MAX_LENGTH = 250

type UnsubscribeReasonProps = {
  reason: string
  setReason: (reason: string) => void
  otherReason: string
  setOtherReason: (reason: string) => void
}

const UnsubscribeReason = ({ reason, setReason, otherReason, setOtherReason }: UnsubscribeReasonProps) => {
  const charSize = otherReason.length
  const charactersRemaining = MAX_LENGTH - charSize
  return (
    <>
      <TextField
        select
        label="I don't like you because..."
        placeholder=""
        value={reason}
        onChange={(e) => {
          setReason(e.target.value)
          setOtherReason('')
        }}
        variant="outlined"
        fullWidth
        required
        margin="normal"
      >
        {reasons.map(({ value, label }) => (
          <MenuItem
            key={value}
            value={value}
          >
            {label}
          </MenuItem>
        ))}
      </TextField>
      {reason === OTHER_REASON && (
        <TextField
          multiline
          label="Additional Feedback"
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
          helperText={`${charactersRemaining} character${charactersRemaining === 1 ? '' : 's'} remaining`}
          inputProps={{ maxLength: MAX_LENGTH }}
        />
      )}
    </>
  )
}

export default UnsubscribeReason
