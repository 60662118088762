import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Tabs, Tab, TextField, Typography, Paper, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Link } from 'react-router-dom'
import { validate } from 'email-validator'
import ReCAPTCHA from 'react-google-recaptcha'

import { COALITION_MEMBER_ALIAS, COALITION_ALIAS, COALITION_MEMBER, COALITION } from 'common/coalition-sources'
import api from 'common/api'

import useRefWrapper from './use-ref-wrapper'
import CollegeAvatarWithTitle from 'components/CollegeAvatarWithTitle'

const useStyles = makeStyles((theme) => ({
  collegeContainer: {
    maxWidth: '328px',
    margin: theme.spacing(3, 'auto'),
    textAlign: 'left',
  },
}))

const useLinkParams = (scid) => {
  const params = {
    source: scid ? COALITION_MEMBER_ALIAS : COALITION_ALIAS,
  }
  if (scid) {
    params.scid = scid
  }
  const query = new URLSearchParams(params)
  return query.toString()
}

const CoalitionSignUp = ({ scid }) => {
  const classes = useStyles()
  const [isValidRecaptcha, setIsValidRecaptcha] = useState(false)
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isInvited, setIsInvited] = useState(false)
  const [hasEmailConflict, setHasEmailConflict] = useState(false)
  const [hasGenericError, setHasGenericError] = useState(false)
  const [collegeName, setCollegeName] = useState('')
  const [studentType, setStudentType] = useState('FirstYear')
  const linkParams = useLinkParams(scid)
  const recaptchaRef = useRefWrapper(null)

  useEffect(() => {
    if (scid) {
      api.get(`/api/student/register/${scid}/name`).then((name) => {
        setCollegeName(name)
      })
    }
  }, [scid])
  const submit = useCallback(async () => {
    setIsSubmitting(true)
    setHasEmailConflict(false)
    setHasGenericError(false)
    api
      .post('/api/student/register/invite', {
        Email: email,
        Source: scid ? COALITION_MEMBER : COALITION,
        Scid: scid,
        StudentType: studentType,
        RecaptchaResponse: recaptchaRef.current.getValue(),
      })
      .then(() => setIsInvited(true))
      .catch((err) => {
        setIsSubmitting(false)
        if (err.status === 409) {
          setHasEmailConflict(true)
        } else {
          setHasGenericError(true)
        }
      })
  }, [studentType, email, scid, recaptchaRef])
  const isDisabled = !email || !isValidEmail || isSubmitting || !isValidRecaptcha
  return (
    <>
      {!!(scid && collegeName) && (
        <Box className={classes.collegeContainer}>
          <Paper>
            <Box p={2}>
              <CollegeAvatarWithTitle
                scid={scid}
                title={collegeName}
                titleComponentProps={{
                  align: 'left',
                }}
              />
            </Box>
          </Paper>
        </Box>
      )}
      {isInvited ? (
        <Box mt={3}>Please check your inbox to verify your email.</Box>
      ) : (
        <>
          {collegeName ? (
            <Typography>
              👋 Yes, you’re in the right place!
              <br />
              You can begin your application to {collegeName} on Scoir. Get started now.
            </Typography>
          ) : (
            <Typography>Join now to begin your journey to college.</Typography>
          )}
          <Box my={3}>
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              value={studentType}
              onChange={(e, studentType) => setStudentType(studentType)}
              aria-label="First Year or Transfer Student"
            >
              <Tab
                label="First Year Student"
                value="FirstYear"
              />
              <Tab
                label="Transfer Student"
                value="Transfer"
              />
            </Tabs>
          </Box>
          <Box my={3}>
            <TextField
              fullWidth
              error={!!email && !isValidEmail}
              value={email || ''}
              label="Email"
              type="email"
              onChange={(e) => {
                const email = e.target.value
                setEmail(email)
                setIsValidEmail(validate(email))
              }}
              onKeyPress={(e) => !isDisabled && e.key === 'Enter' && submit()}
              variant="outlined"
            />
          </Box>
          {hasGenericError && (
            <Alert severity="error">An error occurred while signing up, please try again later.</Alert>
          )}
          {hasEmailConflict && (
            <Alert severity="error">
              Looks like you already have an account with Scoir! <Link to={`/signin?${linkParams}`}>Sign In</Link> using
              that email address.
            </Alert>
          )}
          <Box
            justifyContent="center"
            mt={3}
          >
            <Box display="inline-block">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={window.RECAPTCHA_SITE_KEY}
                onChange={(isValid) => setIsValidRecaptcha(isValid)}
              />
            </Box>
          </Box>
          <Box my={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={isDisabled}
              onClick={submit}
            >
              Sign Up
            </Button>
          </Box>
          <Box my={3}>
            <Typography display="inline">Already have an account?</Typography>
            &nbsp;
            <Link to={`/signin?${linkParams}`}>Sign In</Link>
          </Box>
        </>
      )}
    </>
  )
}

CoalitionSignUp.propTypes = {
  scid: PropTypes.number,
}

export default CoalitionSignUp
