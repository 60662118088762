import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import { isEmpty, head } from 'lodash'

import qs from '@scoir/query-string'
import ComponentLoader from '@scoir/components-core/dist/components/ComponentLoader'

import api from 'common/api'
import { VALID_REDIRECT_URIS, MESSAGE_KEY } from 'components/SSOButtons/LoginWithCleverButton'
import { getTrackEventsSessionId } from 'common/get-track-events-session-id'
import StudentMatch from './StudentMatch'

export const CleverSignUpRoute = ({ state, code }) => {
  const [matchedStudent, setMatchedStudent] = React.useState({})
  const targetOrigin = `${window.location.protocol}//${document.domain}`
  const sessionId = getTrackEventsSessionId()
  const authorizeUser = React.useCallback(
    (creds) => {
      window.opener.postMessage(
        {
          success: true,
          key: MESSAGE_KEY,
          accessToken: creds.access_token,
        },
        targetOrigin
      )
      window.close()
    },
    [targetOrigin]
  )
  useEffect(() => {
    const redirect_uri = `${window.location.protocol}//${window.location.host}/${VALID_REDIRECT_URIS.cleverLink}`
    const query = qs.stringify({ state, code, redirect_uri, sessionId })
    api
      .post(`/api/oauth/callback/clever?${query}`)
      .then((match) => {
        const isRegisteredUser = match.access_token && match.UserId && !isEmpty(match.UserTypes)
        if (isRegisteredUser) {
          authorizeUser(match)
        } else if (head(match.UserTypes) === 'Student') {
          setMatchedStudent(match)
        } else {
          window.opener.postMessage(
            {
              success: false,
              key: MESSAGE_KEY,
            },
            targetOrigin
          )
          window.close()
        }
      })
      .catch(() => {
        window.opener.postMessage(
          {
            success: false,
            key: MESSAGE_KEY,
          },
          targetOrigin
        )
        window.close()
      })
  }, [state, code, targetOrigin, authorizeUser, sessionId])

  const confirmLink = () => {
    const student = {
      ...matchedStudent,
      Id: matchedStudent.UserId,
    }
    delete student.UserId
    const query = qs.stringify({ state, sessionId })
    api
      .post(`/api/oauth/confirm/clever?${query}`, student)
      .then((creds) => authorizeUser(creds))
      .catch(() => {
        window.opener.postMessage(
          {
            success: false,
            key: MESSAGE_KEY,
          },
          targetOrigin
        )
        window.close()
      })
  }

  return (
    <ComponentLoader loaded={!isEmpty(matchedStudent)}>
      <StudentMatch
        student={matchedStudent}
        onSignUp={confirmLink}
      />
    </ComponentLoader>
  )
}

CleverSignUpRoute.propTypes = {
  state: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}

const urlPropsQueryConfig = {
  state: { type: UrlQueryParamTypes.string },
  code: { type: UrlQueryParamTypes.string },
}

export default addUrlProps({ urlPropsQueryConfig })(CleverSignUpRoute)
