export const OTHER_REASON = 'other'
const unsubscribeReasons = [
  {
    value: 'military',
    label: 'I’m joining the military',
  },
  {
    value: 'idk',
    label: 'I don’t know what Scoir is',
  },
  {
    value: 'afford',
    label: 'I don’t think I can afford college',
  },
  {
    value: 'decided',
    label: 'I already know where I’m going to college',
  },
  {
    value: 'trade',
    label: 'I’m attending a trade school',
  },
  {
    value: 'work',
    label: 'I plan to work full-time',
  },
  {
    value: OTHER_REASON,
    label: 'Other',
  },
]

export default unsubscribeReasons
