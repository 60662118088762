import React from 'react'
import { connect } from 'react-redux'

import qs from '@scoir/query-string'
import { useLocation } from 'react-router'

import { actions as snackbarActions } from 'store/snackbar'
import api from 'common/api'
import googleIcon from './google-icon.svg'
import StyledButton from '../styledButton'
import { useTrackEventsSessionId } from 'common/use-track-events-session-id'

type LoginWithGoogleButtonProps = {
  addSnack: (message: string) => void
  scid?: number
  source?: string
}

export const LoginWithGoogleButton = ({ addSnack, source, scid }: LoginWithGoogleButtonProps) => {
  const { pathname } = useLocation()
  const sessionId = useTrackEventsSessionId()

  const onClick = () => {
    const params = {
      scid: scid && scid > 0 ? scid.toString() : undefined,
      source: source,
      action: pathname.includes(`/signin`) ? `login` : undefined,
      sessionId,
    }
    const finalParams = qs.stringify(params)
    api
      .get<string>(`/api/google/init-oauth${finalParams ? `?${finalParams}` : ''}`)
      .then((url) => {
        url = decodeURIComponent(url.replace(/"/g, '')).replace(/\\u0026/g, '&')
        window.location.href = url
      })
      .catch(() => {
        addSnack('Please select an alternate sign in method')
      })
  }
  return (
    <StyledButton
      onClick={onClick}
      icon={googleIcon}
      ssoTitle="Google"
    />
  )
}

export default connect(null, {
  addSnack: snackbarActions.addSnackbarItem,
})(LoginWithGoogleButton)
