import React, { useEffect, useState } from 'react'

import { FormikHelpers } from 'formik'
import moment from 'moment'
import ComponentLoader from '@scoir/components-core/dist/components/ComponentLoader'
import qs from '@scoir/query-string'

import api from 'common/api'
import { clearLocalStorage, routeToApp, setCookies } from 'common/login-service'
import { AuthToken, ConfirmResponse } from 'types/@scoir/apiTypes'
// @ts-ignore
import { formatter } from '@scoir/date'

import Title from 'components/Title'
import RegistrationForm, { RegistrationFormValues } from 'components/RegistrationForm'
import { isValidDate } from 'components/RegistrationForm/helper'
import CountryMappingProvider from 'components/RegistrationForm/CountryMappingProvider'

const StudentRegisterRoute = ({ token }: { token: string }) => {
  const [tokenAuth, setTokenAuth] = useState<AuthToken | undefined>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [confirmError, setConfirmError] = useState(false)
  const [initialValues, setInitialValues] = useState<RegistrationFormValues | undefined>()
  const [hasOtherHighSchool, setHasOtherHighSchool] = useState(false)

  useEffect(() => {
    api
      .post<{}, AuthToken>(`/api/student/register/invite/validate/${token}`, {})
      .then((auth) => {
        const isOtherSchool = Boolean(auth?.HighSchool?.HighSchoolName === 'Other')
        setHasOtherHighSchool(isOtherSchool)
        setTokenAuth(auth)
        setInitialValues({
          firstName: auth.MatchedHighSchoolStudent?.FirstName || '',
          lastName: auth?.MatchedHighSchoolStudent?.LastName || '',
          password: {
            pass: '',
            passConfirm: '',
          },
          birthdate: auth?.BirthDate && isValidDate(auth.BirthDate) ? formatter.format(auth.BirthDate) : undefined,
          gradYear:
            auth?.MatchedHighSchoolStudent?.GraduationYear && auth.MatchedHighSchoolStudent.GraduationYear > 0
              ? auth.MatchedHighSchoolStudent.GraduationYear
              : new Date().getFullYear() + 1,
          confirmTOS: false,
          recaptchaResponse: '',
          country: isOtherSchool ? 'USA' : '',
          city: '',
          stateOrProvince: '',
        })
        setLoading(false)
      })
      .catch((err) => {
        const errMsg =
          err.status === 401
            ? 'This Scoir Invitation is no longer valid.'
            : 'An unexpected error occurred, please try again later.'
        setError(errMsg)
        setLoading(false)
      })
  }, [token])

  const registerStudentUser = (values: RegistrationFormValues, props: FormikHelpers<RegistrationFormValues>) => {
    props.setSubmitting(true)

    const body: { [key: string]: any } = {
      ...tokenAuth,
      HighSchool: tokenAuth?.HighSchool,
      FirstName: values.firstName,
      LastName: values.lastName,
      GraduationYear: values.gradYear,
      Category: 'High School Student',
      Password: {
        Pass: values.password.pass,
        PassConfirm: values.password.passConfirm,
      },
      RecaptchaResponse: values.recaptchaResponse,
      BirthDate: moment(values.birthdate!).format('YYYY-MM-DD'),
      Country: values.country,
      City: values.city,
      State: values.stateOrProvince,
      IsPostGradStudent: tokenAuth?.IsPostGradStudent,
    }

    if (tokenAuth?.MatchedHighSchoolStudent?.HighSchoolStudentId) {
      body.HighSchoolStudentId = tokenAuth.MatchedHighSchoolStudent.HighSchoolStudentId
    }
    const params = qs.stringify({
      sessionId: window.localStorage.getItem('sessionId'),
    })
    api
      .post<{}, ConfirmResponse>(`/api/student/confirm?${params}`, body)
      .then((loginResponse) => {
        if (loginResponse.access_token && loginResponse.UserTypes) {
          const userType = loginResponse.UserTypes[0]
          clearLocalStorage(userType)
          setCookies(userType, loginResponse.access_token)
          routeToApp(userType)
        } else {
          setConfirmError(true)
          props.setSubmitting(false)
        }
      })
      .catch(() => {
        setConfirmError(true)
        props.setSubmitting(false)
      })
  }

  if (error) {
    return <Title subtitle={error} />
  }
  return (
    <CountryMappingProvider>
      <ComponentLoader loaded={!loading}>
        <Title
          title="Welcome to Scoir ✨"
          subtitle={`Please complete your registration ${tokenAuth?.Email ? `for ${tokenAuth.Email}` : ''}`}
        />
        <RegistrationForm
          initialValue={initialValues}
          handleSubmit={registerStudentUser}
          useCaptcha
          showLocation={hasOtherHighSchool}
          confirmError={confirmError}
        />
      </ComponentLoader>
    </CountryMappingProvider>
  )
}

export default StudentRegisterRoute
