import React from 'react'

import { types } from './student-signup-reducer'

const signUpContext = React.createContext({})

export const useRegistrationData = () => {
  const { data } = React.useContext(signUpContext)
  return data
}

export const useSignUpStep = () => {
  const { signUpStep } = React.useContext(signUpContext)
  return signUpStep
}

export const useSelectHighSchool = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return (highSchool) => {
    return onUpdate({
      type: types.SELECT_HIGH_SCHOOL,
      payload: { highSchool },
    })
  }
}

export const useEmailSignUp = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return (highSchool) => {
    return onUpdate({
      type: types.EMAIL_SIGN_UP,
      payload: { highSchool },
    })
  }
}

export const useMatchByEmailFailure = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return (email) => {
    return onUpdate({
      type: types.MATCH_BY_EMAIL_FAILURE,
      payload: { email },
    })
  }
}

export const useTryOtherEmail = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return () => onUpdate({ type: types.TRY_OTHER_EMAIL })
}

export const useFoundMatch = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return (matchIdentifiers, matchedStudent) => {
    return onUpdate({
      type: types.FOUND_MATCH,
      payload: {
        matchIdentifiers,
        matchedStudent,
      },
    })
  }
}

export const useSendInvite = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return (matchedStudent) => {
    const action = matchedStudent.unmatched
      ? { type: types.SEND_UNMATCHED_INVITE, payload: { matchedStudent } }
      : {
          type: types.SEND_INVITE,
          payload: { matchedStudent },
        }
    onUpdate(action)
  }
}

export const useConfirmMatchToCleverSchool = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return () => onUpdate({ type: types.CONFIRM_MATCH_TO_CLEVER_SCHOOL })
}

export const useConfirmMatchToClassLinkSchool = () => {
  const { onUpdate } = React.useContext(signUpContext)
  return () => onUpdate({ type: types.CONFIRM_MATCH_TO_CLASSLINK_SCHOOL })
}

export default signUpContext
