import React from 'react'

import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers'
import { StandardTextFieldProps, TextField, TextFieldProps, makeStyles } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'

// @ts-expect-error
import { isCredibleDate } from '@scoir/date'

const invalidErrorTextId = `date-invalid-error-id-${Math.random().toString(36).substr(2, 9)}`
const minErrorTextId = `date-min-error-id-${Math.random().toString(36).substr(2, 9)}`
const maxErrorTextId = `date-max-error-id-${Math.random().toString(36).substr(2, 9)}`

const CustomTextField = (textFieldProps: TextFieldProps) => {
  const inputProps = {
    'aria-describedby': `${invalidErrorTextId} ${minErrorTextId} ${maxErrorTextId}`,
  }
  return (
    <TextField
      {...textFieldProps}
      inputProps={{
        ...inputProps,
        form: { autoComplete: 'off' },
      }}
      margin="none"
      fullWidth
    />
  )
}

const useStyles = makeStyles(() => ({
  picker: {
    minWidth: '215px',
  },
}))

interface DatePickerProps
  extends Omit<KeyboardDatePickerProps, 'value' | 'InputProps'>,
    Omit<StandardTextFieldProps, 'onChange' | 'onError' | 'variant'> {
  date: Date | string
  onChange: (date: Date | null) => void
  pickerIdSuffix: string
}

const DatePicker = ({ date, onChange, pickerIdSuffix, ...datePickerProps }: DatePickerProps) => {
  const value = isCredibleDate(date) ? date : null
  const classes = useStyles()
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...datePickerProps}
        className={classes.picker}
        value={value}
        autoOk
        margin="normal"
        variant="inline"
        inputVariant="outlined"
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
        id={`${pickerIdSuffix}-date-picker`}
        onChange={onChange as any}
        error={Boolean(datePickerProps.error)}
        TextFieldComponent={CustomTextField}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
