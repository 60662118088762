import React from 'react'

import { connect } from 'react-redux'
import { get } from 'lodash'
import qs from '@scoir/query-string'

import { actions as snackbarActions } from 'store/snackbar'
import api from 'common/api'

import classLinkIcon from './classlink-icon.png'
import StyledButton from '../styledButton'
import { useTrackEventsSessionId } from 'common/use-track-events-session-id'

export const VALID_REDIRECT_URIS = {
  classLinkLogin: 'classlink_login',
}

export const MESSAGE_KEY = 'scoir.classLinkSignUpResult'
export const MESSAGE_LISTENER_EXPIRATION = 300000

type LoginWithClassLinkButtonProps = {
  addSnack: (message: string) => void
  scid?: number
  source?: string
}

const LoginWithClassLinkButton = ({ addSnack, scid, source }: LoginWithClassLinkButtonProps) => {
  const [isRedirecting, setIsRedirecting] = React.useState(false)
  const sessionId = useTrackEventsSessionId()

  const oauthInit = () => {
    const l = get(window, 'location', {})
    const page = VALID_REDIRECT_URIS.classLinkLogin
    // @ts-expect-error - investigate
    const redirectUri = `${l.protocol}//${l.host}/${page}`
    const params = {
      scid: scid && scid > 0 ? scid.toString() : undefined,
      source: source,
    }
    const paramString = qs.stringify(params)
    const query = `?sessionId=${sessionId}&redirect_uri=${redirectUri}${paramString && `&${paramString}`}`
    api
      .get<string>(`/api/oauth/init/classlink${query}`)
      .then((uri) => {
        const decodedUrl = decodeURIComponent(uri.replace(/"/g, '')).replace(/\\u0026/g, '&')

        setIsRedirecting(true)
        window.location.href = decodedUrl
      })
      .catch(() => addSnack('An error occured while signing in with ClassLink.'))
  }

  return (
    <StyledButton
      onClick={oauthInit}
      disabled={isRedirecting}
      icon={classLinkIcon}
      ssoTitle="ClassLink"
    />
  )
}

export default connect(null, {
  addSnack: snackbarActions.addSnackbarItem,
})(LoginWithClassLinkButton)
