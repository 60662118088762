import React from 'react'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { ThemeProvider } from '@material-ui/core/styles'

import { ErrorBoundary } from '@scoir/error-reporter'

import store from './store'
import ErrorReporter from 'components/ErrorReporter'
import AuthRoutes from 'routes/AuthRoutes'

import theme from './theme'

import './bootstrap.scss'
import './App.scss'

const App = () => {
  React.useEffect(() => {
    const preloader = document.getElementById('preloader')
    if (preloader) {
      preloader.remove()
    }
  }, [])
  return (
    <ErrorReporter>
      <ErrorBoundary>
        <BrowserRouter>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <AuthRoutes />
            </ThemeProvider>
          </Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </ErrorReporter>
  )
}

export default App
