import produce from 'immer'
import { get, forEach } from 'lodash'

export const types = {
  SELECT_HIGH_SCHOOL: 'SELECT_HIGH_SCHOOL',
  MATCH_BY_EMAIL_FAILURE: 'MATCH_BY_EMAIL_FAILURE',
  TRY_OTHER_EMAIL: 'TRY_OTHER_EMAIL',
  FOUND_MATCH: 'FOUND_MATCH',
  SEND_INVITE: 'SEND_INVITE',
  SEND_UNMATCHED_INVITE: 'SEND_UNMATCHED_INVITE',
  CONFIRM_MATCH_TO_CLEVER_SCHOOL: 'CONFIRM_MATCH_TO_CLEVER_SCHOOL',
  CONFIRM_MATCH_TO_CLASSLINK_SCHOOL: 'CONFIRM_MATCH_TO_CLASSLINK_SCHOOL',
  EMAIL_SIGN_UP: 'EMAIL_SIGN_UP',
}

const studentSignupReducer = (state, action) => {
  switch (action.type) {
    case types.SELECT_HIGH_SCHOOL: {
      const cleverRequired = get(action.payload.highSchool, 'CleverRequired')
      const classLinkRequired = get(action.payload.highSchool, 'ClassLinkRequired')
      const isActive = get(action.payload.highSchool, 'Active')
      const isOther = get(action.payload.highSchool, 'isOther')
      let signUpStep
      if (cleverRequired) {
        signUpStep = 'CleverRequired'
      } else if (classLinkRequired) {
        signUpStep = 'ClassLinkRequired'
      } else if (!isActive && !isOther) {
        signUpStep = 'EmailSignUp'
      } else {
        signUpStep = 'EmailMatch'
      }

      return {
        ...state,
        signUpStep,
        data: produce(state.data, (draftData) => {
          draftData.highSchool = action.payload.highSchool
        }),
      }
    }
    case types.MATCH_BY_EMAIL_FAILURE: {
      return {
        ...state,
        signUpStep: 'IdDobMatch',
        data: produce(state.data, (draftData) => {
          draftData.email = action.payload.email
        }),
      }
    }
    case types.EMAIL_SIGN_UP: {
      return {
        ...state,
        signUpStep: 'EmailSignUp',
        data: produce(state.data, (draftData) => {
          draftData.highSchool = action.payload.highSchool
        }),
      }
    }
    case types.TRY_OTHER_EMAIL: {
      return {
        ...state,
        signUpStep: 'EmailMatch',
      }
    }
    case types.FOUND_MATCH: {
      return {
        ...state,
        signUpStep: 'MatchConfirm',
        data: produce(state.data, (draftData) => {
          forEach(action.payload.matchIdentifiers, (val, key) => {
            draftData[key] = val
          })
          draftData.matchedStudent = action.payload.matchedStudent
        }),
      }
    }
    case types.SEND_INVITE: {
      return {
        ...state,
        signUpStep: 'StudentInvite',
        data: produce(state.data, (draftData) => {
          draftData.matchedStudent = action.payload.matchedStudent
        }),
      }
    }
    case types.SEND_UNMATCHED_INVITE: {
      return {
        ...state,
        signUpStep: 'UnmatchedStudentInvite',
        data: produce(state.data, (draftData) => {
          draftData.matchedStudent = action.payload.matchedStudent
        }),
      }
    }
    case types.CONFIRM_MATCH_TO_CLEVER_SCHOOL: {
      return {
        ...state,
        signUpStep: 'CleverRequired',
      }
    }
    case types.CONFIRM_MATCH_TO_CLASSLINK_SCHOOL: {
      return {
        ...state,
        signUpStep: 'ClassLinkRequired',
      }
    }
    default:
      throw new Error(`action type '${action.type}' is not a valid type`)
  }
}

export default studentSignupReducer
