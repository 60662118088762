import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FormikHelpers } from 'formik'

import api from 'common/api'
import Title from 'components/Title'
import EmailForm, { EmailFormValues } from 'components/EmailForm'
import ErrorBox from 'components/ErrorBox'

const ForgotPassword = () => {
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)

  const handleResetPassword = (values: EmailFormValues, props: FormikHelpers<EmailFormValues>) => {
    setError(false)
    setEmail(values.email)
    props.setSubmitting(true)
    api
      .post('/api/user/password/reset/request', {
        Email: values.email,
      })
      .then(() => {
        setEmailSent(true)
      })
      .catch(() => {
        setError(true)
        props.setSubmitting(false)
      })
  }

  if (emailSent) {
    return (
      <Title
        title="Check your email 📬"
        subtitle={`Please check ${email} to complete your password reset.`}
      />
    )
  }
  return (
    <>
      {error && (
        <ErrorBox
          error={
            <>
              {`An account for ${email} does not exist. Please reenter your email or `}
              <Link to="/signup">create a new account.</Link>
            </>
          }
        />
      )}
      <Title
        title="Reset your password 🔑"
        subtitle="Please enter your email to receive a reset link."
      />
      <EmailForm
        initialValue={{
          email: '',
        }}
        handleSubmit={handleResetPassword}
        submitText="Send Link"
      />
    </>
  )
}

export default ForgotPassword
