import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { unsubscribe, resubscribe } from './service'
import UnsubscribeReason from './UnsubscribeReason'
import Title from 'components/Title'

const useStyles = makeStyles(() => ({
  mainContent: {
    textAlign: 'left',
  },
  actionContainer: {
    textAlign: 'center',
  },
  submitButton: {
    borderRadius: 40,
    width: '100%',
  },
}))

type UnsubscribeInvitesProps = {
  inviteToken: string
  messageTag: string
  subToken: string
  cleverRequired: boolean
}

const redirectInvite = (inviteToken: string, cleverRequired: boolean) =>
  window.location.assign(
    cleverRequired ? `/confirm/student/clever-signup/${inviteToken}` : `/confirm/student/signup/${inviteToken}`
  )

const UnsubscribeInvites = ({ inviteToken, messageTag, subToken, cleverRequired }: UnsubscribeInvitesProps) => {
  const classes = useStyles()
  const [reason, setReason] = useState('')
  const [otherReason, setOtherReason] = useState('')
  const [hasUnsubscribeError, setHasUnsubscribeError] = useState(false)
  const [hasResubscribeError, setHasResubscribeError] = useState(false)
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    document.title = 'Scoir - Unsubscribe'
  }, [])

  const submit = useCallback(() => {
    setHasUnsubscribeError(false)
    setIsSubmitting(true)
    unsubscribe(reason, otherReason, messageTag, subToken)
      .then(() => setHasUnsubscribed(true))
      .catch(() => setHasUnsubscribeError(true))
      .finally(() => setIsSubmitting(false))
  }, [reason, otherReason, messageTag, subToken])

  const undoSubmit = useCallback(() => {
    setHasResubscribeError(false)
    setIsSubmitting(true)
    resubscribe(messageTag, subToken)
      .then(() => redirectInvite(inviteToken, cleverRequired))
      .catch(() => {
        setIsSubmitting(false)
        setHasResubscribeError(true)
      })
  }, [messageTag, subToken, inviteToken, cleverRequired])
  return (
    <Box>
      <Title
        title="We’re sad to see you go 💔"
        subtitle={
          hasUnsubscribed
            ? `We won't send you any more reminders about this invite, but your counselor may still send you emails via Scoir.`
            : `Please let us know why you’re not interested in joining Scoir. We’d love your feedback.`
        }
      />
      <Box className={classes.mainContent}>
        {!hasUnsubscribed && (
          <Box mb={2}>
            <UnsubscribeReason
              reason={reason}
              setReason={setReason}
              otherReason={otherReason}
              setOtherReason={setOtherReason}
            />
          </Box>
        )}
        {(hasUnsubscribeError || hasResubscribeError) && (
          <Box mb={2}>
            <Typography
              role="alert"
              align="center"
              color="error"
            >
              {hasUnsubscribeError && 'An error occurred while unsubscribing.'}
              {hasResubscribeError && 'An error occurred while resubscribing.'}
            </Typography>
          </Box>
        )}
        <Box className={classes.actionContainer}>
          <Box mb={2}>
            {hasUnsubscribed ? (
              <Button
                color="primary"
                size="large"
                disabled={isSubmitting}
                onClick={undoSubmit}
              >
                I have regrets. I want to try Scoir (and keep receiving emails).
              </Button>
            ) : (
              <Button
                className={classes.submitButton}
                variant="contained"
                color="primary"
                disabled={!reason || isSubmitting}
                onClick={submit}
              >
                Submit
              </Button>
            )}
          </Box>
          {!hasUnsubscribed && (
            <Box mb={2}>
              <Button
                color="primary"
                size="large"
                onClick={() => redirectInvite(inviteToken, cleverRequired)}
              >
                Nevermind, I'd like to try Scoir
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

UnsubscribeInvites.propTypes = {
  inviteToken: PropTypes.string.isRequired,
  messageTag: PropTypes.string.isRequired,
  subToken: PropTypes.string.isRequired,
  cleverRequired: PropTypes.bool,
}

export default UnsubscribeInvites
